<template>
  <div>
    <h5 class="mb-4">
      {{ $t("reportCenter") }} / {{ $t(title) }}
      <span class="text-muted">{{ count }}</span>
    </h5>
    <p class="text-muted">{{ $t(`${title}Description`) }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: "",
    },
    count: {
      default: "",
    },
  },
};
</script>

<style></style>
